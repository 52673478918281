import React, { Component, useCallback, useEffect, useState } from 'react'
import {
    FormControl,
    Row,
    Col,
    Card,
    Tab,
    Tabs,
    Table,
    Form,
    InputGroup,
    Button
} from 'react-bootstrap'

import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'

import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'
import { fetchWithApiKey } from '../../basedata/apiCall'
import { useForm } from 'react-hook-form'

import './reports.sass'

function GameReports() {
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()

    const currentUser = sessionStorage.getItem('role')

    if (currentUser === 'default') {
        window.location.href = '/dashboard'
    }

    const [reportList, setReportList] = useState([])

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [disableSign, setDisableSign] = useState(false)

    const gates = [
        {
            name: 'Matarazzo',
            direction: 'Entraram',
            group: 'Catraca 1',
            IP: '192.168.0.168',
            sync: true
        },
        {
            name: 'Matarazzo',
            direction: 'Entraram',
            group: 'Catraca 2',
            IP: '192.168.0.171',
            sync: false
        },
        {
            name: 'Matarazzo',
            direction: 'Sairam',
            group: 'Catraca 1',
            IP: '192.168.0.169',
            sync: false
        },
        {
            name: 'Matarazzo',
            direction: 'Sairam',
            group: 'Catraca 2',
            IP: '192.168.0.172',
            sync: false
        },
        {
            name: 'Palestra',
            direction: 'Entraram',
            group: 'Catraca 1',
            IP: '192.168.0.174',
            sync: false
        },
        {
            name: 'Palestra',
            direction: 'Entraram',
            group: 'Catraca 2',
            IP: '192.168.0.177',
            sync: false
        },
        {
            name: 'Palestra',
            direction: 'Sairam',
            group: 'Catraca 1',
            IP: '192.168.0.175',
            sync: false
        },

        {
            name: 'Palestra',
            direction: 'Sairam',
            group: 'Catraca 2',
            IP: '192.168.0.178',
            sync: false
        },
        {
            name: 'Estacionamento',
            direction: 'Entraram',
            group: 'Catraca 1',
            IP: '192.168.0.180',
            sync: false
        },
        {
            name: 'Estacionamento',
            direction: 'Entraram',
            group: 'Catraca 2',
            IP: '192.168.0.183',
            sync: false
        },
        {
            name: 'Estacionamento',
            direction: 'Sairam',
            group: 'Catraca 1',
            IP: '192.168.0.181',
            sync: false
        },

        {
            name: 'Estacionamento',
            direction: 'Sairam',
            group: 'Catraca 2',
            IP: '192.168.0.184',
            sync: false
        },
        {
            name: 'Conveniência',
            direction: 'Entraram',
            group: 'Catraca 1',
            IP: '192.168.0.186',
            sync: false
        },
        {
            name: 'Conveniência',
            direction: 'Entraram',
            group: 'Catraca 2',
            IP: '192.168.0.197',
            sync: false
        },
        {
            name: 'Conveniência',
            direction: 'Sairam',
            group: 'Catraca 1',
            IP: '192.168.0.187',
            sync: false
        },

        {
            name: 'Conveniência',
            direction: 'Sairam',
            group: 'Catraca 2',
            IP: '192.168.0.190',
            sync: false
        }
    ]

    const findGate = (gateIP: any) => {
        const currentGate = gates.find((item) => item.IP === gateIP)
        return currentGate ? `${currentGate.name}` : '-'
    }

    // Get bellow info
    const getGameReport = (reportFilter: any) => {
        fetchWithApiKey(`${BASE.api_game.base_url}${BASE.api_game.game_report}/${reportFilter.game}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                setReportList(response)
                setDisableSign(false)
            })
            .catch((error) => {
                console.log(error)
                setDisableSign(false)
            })
    }

    const dateConvert = (date: any) => {
        const eventDate = new Date(date)

        const options = {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        }

        return `${eventDate.toLocaleDateString(
            'pt-br'
        )} ${eventDate.getHours()}:${eventDate.getMinutes()}:${eventDate.getSeconds()}`
    }

    const onSubmit = (data: any) => {
        getGameReport(data)
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title as="h5">Pesquisar</Card.Title>
                <span className="d-block m-t-5">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group>
                            <Form.Label>Tipo de título</Form.Label>
                            <Row>
                                <Col sm={6} md={2}>
                                    <Form.Check
                                        type="checkbox"
                                        id="visitor"
                                        label="Visitante"
                                        {...register('visitor')}
                                    />
                                </Col>
                                <Col sm={6} md={2}>
                                    <Form.Check
                                        type="checkbox"
                                        id="guest"
                                        label="Convidado"
                                        {...register('guest')}
                                    />
                                </Col>
                                <Col sm={6} md={2}>
                                    <Form.Check
                                        type="checkbox"
                                        id="associate"
                                        label="Associado"
                                        {...register('associate')}
                                    />
                                </Col>
                                <Col sm={6} md={2}>
                                    <Form.Check
                                        type="checkbox"
                                        id="provider"
                                        label="Prestador"
                                        {...register('provider')}
                                    />
                                </Col>
                                <Col sm={6} md={2}>
                                    <Form.Check
                                        type="checkbox"
                                        id="employee"
                                        label="Funcionário"
                                        {...register('employee')}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>

                        <Row>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Jogo</Form.Label>
                                    <FormControl
                                        aria-label="Small"
                                        aria-describedby="inputGroup-sizing-sm"
                                        {...register('game')}
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={3}>
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Label>Dispositivo</Form.Label>
                                    <Form.Control as="select">
                                        <option>Todos</option>
                                        <option>Matarazzo</option>
                                        <option>Palestra</option>
                                        <option>Estacionamento</option>
                                        <option>Conveniência</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>

                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Data de acesso:</Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        locale="pt-br"
                                        dateFormat="dd/MM/yyyy"
                                        selected={startDate}
                                        onChange={(date: Date) => setStartDate(date)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                {' '}
                                <Form.Group>
                                    <Form.Label>à:</Form.Label>
                                    <DatePicker
                                        className="form-control"
                                        locale="pt-br"
                                        dateFormat="dd/MM/yyyy"
                                        selected={endDate}
                                        onChange={(date: Date) => setEndDate(date)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={3}>
                                {' '}
                                <Form.Group>
                                    <Button
                                        disabled={disableSign}
                                        type="submit"
                                        className="mb-0 w-100"
                                        onClick={() => clearErrors()}
                                    >
                                        Pesquisar
                                    </Button>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </span>
            </Card.Header>
            <Card.Body>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Ingresso</th>
                            <th>Cpf</th>
                            <th>Torcedor</th>
                            <th>Ocorrência</th>
                            <th>Biometria</th>
                            <th>Operador</th>
                            <th>OnBoarding:</th>
                            <th>Local:</th>
                            <th>horário</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportList &&
                            reportList.map((item: any, index) => (
                                <tr key={index}>
                                    <td scope="row">{item.ticket}</td>
                                    <td scope="row">{item.cpf}</td>
                                    <td scope="row">{item.name}</td>
                                    <td>Não foi liberado</td>
                                    <td scope="row">{item.reason}</td>
                                    <td scope="row">{item.operator[0]?.name}</td>
                                    <td scope="row">{item.images?.onboarding === 'na' ? 'Sem biometria' : ''}</td>
                                    {/* <td><img className='report-image' src={`data:image/png;base64,${item.images?.onboarding}`} alt="n/a" /></td>
                                    <td><img className='report-image' src={`data:image/png;base64,${item.images?.local}`} alt="n/a" /></td> */}
                                    <td>{dateConvert(item.logData)}</td>
                                </tr>
                            ))}
                    </tbody>
                </Table>
            </Card.Body>
        </Card>
    )
}

export default GameReports
