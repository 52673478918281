import axios, { AxiosRequestConfig } from 'axios';

const sessionItem:any = window.sessionStorage.getItem('user-key')
const {user_key, tenant_id} = sessionItem ? JSON.parse(sessionItem): {user_key:null, tenant_id: null}

const apiCompany = axios.create({
  baseURL: 'https://service-company-563791290358.southamerica-east1.run.app',
  headers: {
    'x-api-key': user_key,
    'x-tenant-id': tenant_id
  }
});

const apiEvent = axios.create({
  baseURL: 'https://service-event-maracana-sec-563791290358.southamerica-east1.run.app',
  headers: {
    'x-api-key': user_key,
    'x-tenant-id': tenant_id
  }
});

const apiUser = axios.create({
  baseURL: 'https://service-user-maracana-sec-563791290358.southamerica-east1.run.app',
  //baseURL: 'http://127.0.0.1:3104',
  headers: {
    'x-api-key': user_key,
    'x-tenant-id': tenant_id
  }
});

const apiDashboard = axios.create({
  baseURL: 'https://santos-dashboard-api-prod-74hf2txj6q-rj.a.run.app',
  //baseURL: 'http://127.0.0.1:3104',
  headers: {
    'x-api-key': user_key,
    'x-tenant-id': tenant_id
  }
});

export { apiCompany, apiEvent, apiUser, apiDashboard };
