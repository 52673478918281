import React, { useState, useEffect } from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  FormControl, ProgressBar
} from 'react-bootstrap'
import './findUserFull.sass'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'
import { fetchWithApiKey } from '../../basedata/apiCall'

import { useForm } from 'react-hook-form'

import DatePicker, { registerLocale } from 'react-datepicker'
import ptBR from 'date-fns/locale/pt-BR'
import 'react-datepicker/dist/react-datepicker.css'

const MySwal = withReactContent(Swal)


const maskCPF = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}

const maskDate = (value: any) => {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
}

export function UpdateUser() {

  const queryParameters = new URLSearchParams(window.location.search)

  const userId = queryParameters.get("id")

  useEffect(() => {
    getUserById(userId)
  }, [])

  const [userList, setUserList] = useState([])

  const [isLoading, setLoading] = useState(false)

  const [resetedBio, setResetedBio] = useState(false)

  const [selectedDate, setSelectedDate] = useState(null);

  const role = sessionStorage.getItem('role')


  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };
    const [userSearch, setUserSearch] = useState({
        identifier: '',
        type: 'document'
    })

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues
    } = useForm()

    const {
        register: register2,
        handleSubmit: handleSubmit2,
        formState: { errors: errors2 },
        clearErrors: clearErrors2,
        getValues: getValues2,
    } = useForm()

  const onSubmitDelete= (data: any) =>{
    MySwal.fire({
      title: 'Tem certeza que deseja deletar o usuário?',
      text: "Nós saberemos que foi você! ;)",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#006f3c',
      cancelButtonText:
        'Cancelar',
      confirmButtonText: 'Apagar!'
    }).then(async (result) => {
      if(result.isConfirmed) {
        const operatorId = sessionStorage.getItem('token')
        const res=  await deleteUser(data, operatorId || '')
        setLoading(false)
        if(res.status === 201) {
          MySwal.fire({
            title: 'Apagado!',
            text: 'O usuário foi apagado com sucesso.',
            icon:'success',
            confirmButtonColor: '#006f3c',
          } )
          setUserList([])
        } else {
          MySwal.fire({
              title:   'Algo não deu certo!',
              text:   'Atualize a página e tente novamente',
              icon: 'error',
              confirmButtonColor: '#006f3c',
          }

          )
          setUserList([])
        }
      } else {
        MySwal.fire({
          title:  'Quase!',
          text:   'Sempre é bom confirmar! ;-)',
          icon:    'info',
          confirmButtonColor: '#006f3c',
          confirmButtonText: 'Obrigado'
        })
        }
      }
    )
  }
  const deleteUser = (userId: string, operatorId: string) => {
    setLoading(true)
    return  fetchWithApiKey(`${BASE.api.base_url}${BASE.api.delete_user}/${userId}/operator/${operatorId}`, {
      method: 'DELETE',
      headers: {
        'x-api-key': CONFIG.API_KEY_
      },
    })
  }
    const onSubmit = (data: any) => {
        setLoading(true)
        setUserList([])
        fetchWithApiKey(`${BASE.api.base_url}${BASE.api.find_user_with_biometry}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify({
                userId: data.identifier.replace(/-/g, '').replace(/\./g, ''),
                companyId: 'd5c19737-7e4e-4dd8-9931-d6bc3bc961d1',
                userSearchType: (data.identifier.length > 10)? 'document': 'accessCode',
                userType: 'clube'
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setResetedBio(false)
                setUserList(response.data || [])
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }
    const getUserById = (userId: any) => {
      setLoading(true)
      fetchWithApiKey(`${BASE.api.base_url}${BASE.api.find_user_with_biometry}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: CONFIG.API_KEY
        },
        body: JSON.stringify({
          userId: userId,
          userSearchType: 'id'
        })
      })
        .then((resg) => resg.json())
        .then((response) => {
          setLoading(false)
          setUserList(response.data)
        })
        .catch((error) => {
          setLoading(false)
          console.log(error)
        })
    }
    const updateUser = (data: any) => {
      let birthDate = ''
      if(data?.birthDate) {
         birthDate = `${data.birthDate.split('/')[2]}-${data.birthDate.split('/')[1]}-${data.birthDate.split('/')[0]}`
       }
        setLoading(true)

        fetchWithApiKey(`${BASE.api_user.base_url}${BASE.api_user.user_edit}/${data.userId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
              'x-api-key': CONFIG.API_KEY_1
            },
            body: JSON.stringify({
                document: data.document,
                externalId: data.externalId,
                accessCode: data.access_code,
                lastName:data.lastName,
                firstName: data.firstName,
                email : data.email,
                birthDate: birthDate
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                getUserById(userId)
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    const removeBiometry = (userId: any, userData: any) => {

        setLoading(true)
        setResetedBio(false)
        if (!confirm('Tem certeza?')) {
            setLoading(false)
            return false
        }

        fetchWithApiKey(`${BASE.api.base_url}${BASE.api.remove_biometry}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify({
                user: userId,
                document: userData[1]
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setResetedBio(true)
                generateLog({ affectationId: userId })
                onSubmit({
                    type: userData[0],
                    identifier: userData[1]
                })
            })
            .catch((error) => {
                setLoading(false)
                setResetedBio(false)
                console.log(error)
            })
    }

    const generateLog = (data: any) => {
        const requestData = {
            userId: sessionStorage.getItem('token'),
            detail: {
                rule: sessionStorage.getItem('role'),
                process: 'biometry delete for game'
            },
            affectation: data.affectationId
        }
        fetchWithApiKey(`${BASE.api.base_url}${BASE.api.log_create}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            },
            body: JSON.stringify(requestData)
        })
    }


    const [shotToast, setShotToast] = useState(true);

    const toggleToas = () => setShotToast(!shotToast);

    const copyLink = (link: any) => {

        toggleToas()
        navigator.clipboard.writeText(link);


    }
  const userSyncProgress = (syncStatus: any) => {
    if (!syncStatus) {
      return -1
    }
    const totalSync = syncStatus.length;
    const partialSync = syncStatus.filter((currentElement: any) => currentElement.isFaceCreated == 'completed').length;

    // if (syncStatus[0].updatedAt.split('2')) {

    // }


    if (syncStatus[0] && syncStatus[0].updatedAt) {
      const date1 = new Date(syncStatus[0].updatedAt);
      const date2 = new Date();
      const diffTime = Math.abs(date2.valueOf() - date1.valueOf());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays > 2 && syncStatus[0].isFaceCreated === false) {
        return -1
      }

    }

    console.log('partial', syncStatus)
    console.log('total', totalSync)

    return Math.round((partialSync / totalSync) * 100);
  }
  const resyncBiometry = (token: any) => {
    setLoading(true)

    fetchWithApiKey(`${BASE.api_user.base_url}${BASE.api_user.sync_token}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify({
        token
      })
    })
      .then((resg) => resg.json())
      .then((response) => {
        setLoading(false)
        alert('Sincronização em andamento!')
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
      })
  }
    return (
        <>
            <Card>
                <Card.Header>

                </Card.Header>

                <Card.Body>
                    {isLoading &&
                        <div className='local-loader'>
                            <i
                                className="fa fa-spinner fa-spin text-c-green f-10 m-r-15"
                                title="oiee"
                            />
                        </div>}
                  {userList.length == 0 &&
                        <div>
                            <p className="m-1 text-center label-title">
                                Nenhum registro encontrado :(
                            </p>
                        </div>}
                            {userList.length > 0 &&
                                userList.map((user: any) => (
                                    <>
                                      <div key={user.hikiId} className=''>
                                        <p>
                                          {user.userAvatar != null && <img className='center' width={200}
                                                                           src={`data:image/png;base64,${user.userAvatar}`} />}
                                          {user.userAvatar == null &&
                                            <img className='center' width={200} src={`placeholder-person.png`} />}
                                        </p>
                                        <a
                                          href='#'
                                          className={`label user-trigger btn-ghost f-12`}
                                          onClick={() =>
                                            removeBiometry(
                                              user.token,
                                              getValues(['type', 'identifier'])
                                            )
                                          }
                                        >
                                          Refazer Biometria
                                        </a>
                                        <a
                                          href='#'
                                          className={`label user-trigger btn-ghost`}
                                          onClick={() =>
                                            resyncBiometry(
                                              user.token
                                            )
                                          }
                                        >
                                          Ressincronizar
                                        </a> <br />
                                      </div>
                                      <div>
                                        <p className="m-0 label-title">Sincronização:
                                          <span className='status-danger'>{user.syncStatus ? '' : ' Não sincronizado'}</span>
                                        </p>
                                        {user.syncStatus && userSyncProgress(user.syncStatus) < 100 ?

                                          (userSyncProgress(user.syncStatus) < 0) ?
                                            <ProgressBar variant="danger" label={'erro'} now={100} /> :
                                            (<ProgressBar animated label={`${userSyncProgress(user.syncStatus)}%`} now={userSyncProgress(user.syncStatus)} />) :
                                          userSyncProgress(user.syncStatus) > 0 ?
                                            (
                                              <ProgressBar variant="success" label={'completa'} now={100} />
                                            ) : ''
                                        }

                                        <form onSubmit={handleSubmit(updateUser)}>

                                          <p>
                                            <label>Nome:</label>
                                            <FormControl
                                              aria-label='Small'
                                              defaultValue={user.firstName}
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        {...register('firstName', {
                                                            shouldUnregister: true,
                                                          })}
                                                        disabled={role === 'generalEditor'}
                                                    />
                                                </p>
                                                <p>
                                                    <label>Sobrenome:</label>
                                                    <FormControl
                                                        aria-label="Small"
                                                        defaultValue={user.name}
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        {...register('lastName',{
                                                          shouldUnregister: true,
                                                        })}
                                                        disabled={role === 'generalEditor'}
                                                    />
                                                </p>
                                                <p>
                                                    <label>Documento:</label>
                                                    <FormControl
                                                        aria-label="Small"
                                                        type="text"
                                                        defaultValue={user.document}
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        {...register('document',{
                                                          shouldUnregister: true,
                                                        })}
                                                    />
                                                </p>
                                                <p>
                                                    <label>E-mail:</label>
                                                    <FormControl
                                                        aria-label="Small"
                                                        defaultValue={user.email}
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        {...register('email',{
                                                          shouldUnregister: true,
                                                        })}
                                                    />
                                                </p>

                                                <p>

                                                  <Form.Label>Data Nascimento</Form.Label>
                                                  <Form.Control
                                                    defaultValue={`${user.birthDate.split('-')[2]}/${user.birthDate.split('-')[1]}/${user.birthDate.split('-')[0]}`}
                                                    {...register('birthDate', { shouldUnregister: true})}
                                                    onChange={(event) => {
                                                      const { value } = event.target
                                                           console.log(`${value.split('/')[2]}-${value.split('/')[1]}-${value.split('/')[0]}`)
                                                      // user.birthDate = `${value.split('/')[2]}-${value.split('/')[1]}-${value.split('/')[0]}`
                                                      event.target.value = maskDate(value)

                                                    }}

                                                  />

                                                </p>
                                                <p>
                                                    <label>Matrícula:</label>
                                                    <FormControl
                                                        aria-label="Small"
                                                        defaultValue={user.externalId}
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        {...register('externalId',{
                                                          shouldUnregister: true,
                                                        })}
                                                    />
                                                </p>
                                                  <p>
                                                    <label>Carteirinha:</label>
                                                    <FormControl
                                                        aria-label="Small"
                                                        defaultValue={user.access_code}
                                                        aria-describedby="inputGroup-sizing-sm"
                                                        {...register('access_code',{
                                                          shouldUnregister: true,
                                                        })}
                                                    />
                                                </p>

                                                  <FormControl
                                                    defaultValue={user.hikId}
                                                    type='hidden'
                                                    {...register('userId',{
                                                      shouldUnregister: true,
                                                    })}
                                                  />

                                                <hr />
                                                <Button
                                                    type="submit"
                                                    className="mb-0 w-100"
                                                    onClick={() => clearErrors()}
                                                >Atualizar

                                                </Button>

                                                </form>
                                              <br/>
                                              <button
                                                className={`label user-delete btn-ghost-delete f-12 ${role === 'generalEditor' ? 'disabled-button' : ''}`}
                                                onClick={() =>
                                                  onSubmitDelete(
                                                    user.hikId,
                                                  )
                                                }
                                                disabled={role === 'generalEditor'}
                                              >
                                                Apagar Usuário
                                              </button>
                                            </div>
                                    </>
                                ))}
                </Card.Body>
            </Card>
        </>
    )
}
