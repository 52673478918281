import React from 'react'
import './findUserFull.sass'
import { Modal } from 'react-bootstrap'

interface Props {
  biometricsList: any[],
}

const ListBiometrics = ({ biometricsList}:Props) => {
  const convertDateString = (date:string) => {
    const dateString = new Date(date);

    const convertedDate = dateString.toLocaleString('pt-BR');
    return convertedDate.replace(', ', ' ')
  }
  return (
    <Modal.Body className='biometrics-content'>
      {biometricsList && biometricsList.map((biometric: any, index: number) => (
        <div key={index}>
          <img className='center' width={160} src={`data:image/png;base64,${biometric.base64ResizedPhoto}`} />
          <span>{convertDateString(biometric.captureDate)}</span>
        </div>
      ))}
    </Modal.Body>
  )
}
export default ListBiometrics
