import React, { useState } from 'react'
import {
    Row,
    Col,
    Card,
    Button,
    FormControl,
    Table,
} from 'react-bootstrap'
import './findUserFull.sass'

import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'

import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'
import { fetchWithApiKey } from '../../basedata/apiCall'

import { useForm } from 'react-hook-form'


const maskCPF = (value: any) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}

export function FindNewCUser() {
    const [user, setUser] = useState({
      birthDate: "",
  document: "",
  externalId: null,
  firstName: "",
  lastName: "",
  phoneNumber: "" })
    const [isLoading, setLoading] = useState(false)
    const [error, setErrorSearch] = useState(false)
    const [resetedBio, setResetedBio] = useState(false)
    const [userSearch, setUserSearch] = useState({
        identifier: '',
        type: 'document'
    })

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues
    } = useForm()

    const onSubmit = (data: any) => {
        setLoading(true)
      setErrorSearch(false)
      setUser({birthDate: "",
        document: "",
        externalId: null,
        firstName: "",
        lastName: "",
        phoneNumber: "" })
        fetchWithApiKey(`${BASE.api.base_url}${BASE.api.find_user_newc}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
              'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                externalId: data.identifier.replace(/-/g, '').replace(/\./g, ''),
                userSearchType: (data.identifier.length > 10)? 'document': 'externalId',
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                setResetedBio(false)
              console.log(response.data)
                if(response?.data){
                  setUser(response.data)
                } else {
                  setErrorSearch(true)
                }

            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }


    const [shotToast, setShotToast] = useState(true);
    const toggleToas = () => setShotToast(!shotToast);
    return (
        <>
            <Card>
                <Card.Header>
                    <Card.Title as="h5" className="search-header">
                        Consulta NewC
                    </Card.Title>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col className="mb-2" lg={12} xl={8}>
                                <input
                                    placeholder="CPF ou NewCID"
                                    className="form-control"
                                    {...register('identifier', { required: true })}
                                />
                            </Col>
                            <Col className="mb-2" lg={12} xl={4}>
                                <input
                                    className="btn w-100 btn-primary"
                                    type="submit"
                                    value="Pesquisar"
                                    onClick={() => clearErrors()}
                                />
                            </Col>
                        </Row>
                    </form>
                </Card.Header>

                <Card.Body>
                    {isLoading &&
                        <div className='local-loader'>
                            <i
                                className="fa fa-spinner fa-spin text-c-green f-10 m-r-15"
                                title="oiee"
                            />
                        </div>}
                    <Table responsive>
                        <tbody>
                        {error   &&
                          <>
                            <tr  className="">
                              <td>
                                <p className='user-data-game'>
                                  <h2>Nenhuma informação encontrada</h2><br />
                                </p>

                                <hr />
                              </td>
                            </tr>
                          </>}
                            {user.document != '' &&
                                    <>
                                        <tr  className="">
                                            <td>
                                                <p className='user-data-game'>
                                                    <span>ID NewC:</span> {user.externalId}<br />
                                                    <span>Nome:</span> {user.firstName} {user.lastName} <br />
                                                    <span>Documento:</span> {user.document} <br />
                                                    <span>Data Nasc.:</span> {`${user.birthDate.split('-')[2]}/${user.birthDate.split('-')[1]}/${user.birthDate.split('-')[0]}`}<br />
                                                    <span>Telefone:</span> {user.phoneNumber}
                                                </p>

                                                <hr />
                                            </td>
                                        </tr>
                                    </>
                            }
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </>
    )
}
