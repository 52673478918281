import React, { useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
  Toast,
  Modal,
  FormSelect
} from 'react-bootstrap'

import { useForm } from 'react-hook-form'
import { BASE } from '../basedata/constants'
// import './visitorCreate.sass'
import Swal from 'sweetalert2'


import 'react-datepicker/dist/react-datepicker.css'
import withReactContent from 'sweetalert2-react-content'
import { CONFIG } from '../config/constant'
import { fetchWithApiKey } from '../basedata/apiCall'


const MySwal = withReactContent(Swal)


interface Props {
  terminalData: any
}

export function PassportList({ terminalData}:  Props) {
  

const dateFormatter = (dateTime:string)=>{

  const options:any = {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const oldDate = new Date(dateTime)
  return oldDate.toLocaleDateString('pt-BR', options).split(',').join(' ')
}


const [currentTerminal, setCurrentTerminal] = useState<any>({})

const [show, setShow] = useState(false);

const handleClose = () => {setShow(false);  setCurrentTerminal({})};
const handleShow = () => setShow(true);

const [disableSign, setDisableSign] = useState(false)

const [showConfirm, setShowConfirm] = useState(false);

const handleShowConfirm = () => setShowConfirm(true)
const handleCloseConfirm = () => setShowConfirm(false)

const {
  register,
  handleSubmit,
  setError,
  formState: { errors },
  clearErrors,
  getValues,
  reset
} = useForm()

  const editCoordinator = (data: any) => {

    fetchWithApiKey(`${BASE.api_game.base_url}${BASE.api_game.terminals}/${terminalData.id}`, {
        method: 'Put',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': BASE.api.api_key
        },
        body: JSON.stringify({
          ...data,
          isActive: (data.isActive === 'true') ? true:false,
          status: (data.isActive === 'true') ? 'active':'inactive'
        })
    })
        .then((response) => {
            window.location.reload();
        })
        .catch((error) => {
            console.log(error)
        })
  }


  const initialUserState = {
    access_code: null,
    biometric: true,
    biometric_create: '',
    birthDate: '',
    document: '',
    userAvatar: '',
    docFront: '',
    docBack: '',
    documentValidation: '',
    document_approved: '',
    email: '',
    externalId: '',
    firstName: '',
    hikId: '',
    id: '',
    name: '',
    token: ''
};
  
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(initialUserState);

  const openModal = (user : any) => {
    setSelectedUser(user);
    setModalOpen(true);
};

const closeModal = () => {
    setModalOpen(false);
    setSelectedUser(initialUserState);
};


const getUser = (document:any, biometryId:any) => {

  fetchWithApiKey(`${BASE.api.base_url}${BASE.api.find_user_with_biometry_passport}`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'x-api-key': CONFIG.API_KEY_1
      },
      body: JSON.stringify({
          userId: document.replace(/-/g, '').replace(/\./g, ''),
          companyId: 'c48126a8-a719-48ca-a3a7-e19eaa388da4',
          userSearchType: 'document',
          biometry: biometryId,
          userType: 'game'
      })
  })
      .then((resg) => resg.json())
      .then((response) => {
        openModal(response.data[0])
      })
      .catch((error) => {
          console.log(error)
      })
}

  const forceApproval = (userToken: any) => {

    if(!confirm('Tem certeza?')){
      return true
    }

    const requestData = {
        token: userToken.token,
        biometry: userToken.biometryId,
        operator:  sessionStorage.getItem('token'),
    }
    fetchWithApiKey(`${BASE.api_user.base_url}${BASE.api_user.force_update}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: CONFIG.API_KEY
        },
        body: JSON.stringify(requestData)
    })

    MySwal.fire({
      title: 'Aprovado!',
      text: 'O usuário foi atualizado com sucesso.',
      icon:'success',
      confirmButtonColor: '#006f3c',
    } )

    setModalOpen(false)
}

  const modalOpen = (item:any) => {
    setCurrentTerminal({})
  
    setTimeout(() => {
      setCurrentTerminal(item)
      handleShow()
    }, 300);
  }

  const removeBiometry = (userId: any, userData: any) => {

    if (!confirm('Tem certeza?')) {
        return false
    }

    fetchWithApiKey(`${BASE.api.base_url}${BASE.api.remove_biometry}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: CONFIG.API_KEY
        },
        body: JSON.stringify({
            user: userId,
            document: userData[1]
        })
    })
        .then((resg) => resg.json())
        .then((response) => {
            window.location.reload()
        })
        .catch((error) => {
            console.log(error)
        })
}
  

  return (
    <>

<tr onClick={() => {getUser(terminalData.document, terminalData.biometryId);}}>
  <td>{terminalData.firstName} {terminalData.lastName}</td>
  <td>{terminalData.document}</td>
  <td>{dateFormatter(terminalData.biometryData)}</td>
  <td>{terminalData.isActive ? 'Ativo':'Inativo'}</td>
</tr>

<Modal show={isModalOpen} onHide={closeModal} size="lg" className="modal-container">
                <Modal.Header closeButton className="modal-header">
                    <Modal.Title>Aprovação Manual</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedUser && (
                        <>
                            <div className="grid-container">
                                <div className="column">
                                    <div className="info-header">
                                        {selectedUser.userAvatar ? (
                                            <img
                                                className="avatar"
                                                src={`data:image/png;base64,${selectedUser.userAvatar}`}
                                                alt="Avatar do Usuário"
                                            />
                                        ) : (
                                            <img
                                                className="avatar"
                                                src="placeholder-person.png"
                                                alt="Avatar Padrão"
                                            />
                                        )}
                                    </div>

                                    <div className="image-container">
                                        {selectedUser.docFront ? (
                                            <img
                                                className="document"
                                                src={`data:image/png;base64,${selectedUser.docFront}`}
                                                alt="Frente do Documento"
                                            />
                                        ) : (
                                            <p>Imagem da frente do documento não disponível.</p>
                                        )}
                                        <p><strong>Frente do Documento</strong></p>
                                    </div>
                                </div>

                                <div className="column">
                                    <div className="info-header">
                                        <p><strong>Nome:</strong> {selectedUser.firstName} {selectedUser.name}</p>
                                        <p><strong>Documento:</strong> {selectedUser.document}</p>
                                        <p><strong>Data de Nascimento:</strong> {selectedUser.birthDate.split('-').reverse().join('/')}</p>
                                    </div>

                                    <div className="image-container">
                                        {selectedUser.docBack ? (
                                            <img
                                                className="document"
                                                src={`data:image/png;base64,${selectedUser.docBack}`}
                                                alt="Verso do Documento"
                                            />
                                        ) : (
                                            <p>Imagem do verso do documento não disponível.</p>
                                        )}
                                        <p><strong>Verso do Documento</strong></p>
                                    </div>
                                </div>
                            </div>

                        </>
                    )}
                </Modal.Body>
                <Modal.Footer> 
                  <Row>
                    <Col xs={6}>
                    <Button variant="ghost" onClick={() => removeBiometry(selectedUser.token, getValues(['type', 'identifier']))}>
                        Refazer Biometria
                    </Button>
                    </Col>
                    <Col xs={6}>
                    <Button variant="success" onClick={() => forceApproval(selectedUser)}>
                        Forçar Aprovação
                    </Button>
                    </Col>
                  </Row>
                </Modal.Footer>
            </Modal>
    </>
  )
}
