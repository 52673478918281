import React, { Component, useCallback, useEffect, useState } from 'react'
import {
  Row,
  Col,
  Card,
  Tab,
  Tabs,
  Table,
  Button,
  Form,
  Alert,
  FloatingLabel
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'
import logoBePass from '../../assets/images/bepass_logo_default.png'

import {
  BsEmojiSunglasses,
  BsSun,
  BsPeople,
  BsPersonBoundingBox,
  BsEmojiWink
} from 'react-icons/bs'
import { VisitorCreate } from '../components/visitorCreate'
import { AccessLog } from '../components/accessLog'
import { FindUser } from '../components/findUser'
import { OpenGate } from '../components/openGate'
import { FindUserFull } from '../components/findUserFull'
import { TotalUsers } from '../components/totalUsers'
import { DailyAccess } from '../components/dailyAccess'
import { GateStatus } from '../components/gateStatus'
import { useForm } from 'react-hook-form'

import { CONFIG } from '../../config/constant'
import { BASE } from '../../basedata/constants'
import { fetchWithApiKey } from '../../basedata/apiCall'


import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./../../basedata/firebase";
import { apiCompany } from '../../config/ApiCall'

function decodeJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${c.charCodeAt(0).toString(16).padStart(2, '0')}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}


function Login() {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
    getValues,
    reset
  } = useForm()

  const authCheck = sessionStorage.getItem('auth')
  if (authCheck === 'true') {
    window.location.href = '/dashboard'
  }

  const [loginError, setLoginError] = useState({
    status: false,
    message: ''
  })

  const onSubmit = async (data: any) => {
    // if (data.user === 'teste@bepass.com' && data.password === 'test123') {
    //   sessionStorage.setItem('auth', 'true')
    //   return (window.location.href = '/dashboard')
    // }

    setLoginError({
      status: false,
      message: ''
    })


    try {

      let isThereAnRole = false
      const userCredential:any = await signInWithEmailAndPassword(auth, data.user, data.password);
      if(userCredential?.user?.accessToken) {
        sessionStorage.setItem('token', userCredential?.user?.accessToken)
        sessionStorage.setItem('user-key', JSON.stringify({user_key: userCredential?.user?.accessToken, tenant_id: BASE.company_data.tenant}))
        const sessionItem:any = window.sessionStorage.getItem('user-key')
        const {user_key, tenant_id} = sessionItem ? JSON.parse(sessionItem): {user_key:null, tenant_id: null}
        const getRolesAndToken: any = await apiCompany.get(`/firebase/user/roles/${userCredential.user.uid}`,
         {
            headers: {
              'x-api-key': user_key,
              'x-tenant-id': tenant_id
            }
          }
        )

        if(getRolesAndToken?.data?.token) {

          console.log(getRolesAndToken.data)

          for(const item of getRolesAndToken.data.data.loginRoles){
            if(item.companyId === BASE.company_data.id && 
              item.applications.id === BASE.company_data.applicationId
            ){
              isThereAnRole = true
              if(item.role.roleId === 1){
                sessionStorage.setItem('role', 'bedimin')
              }else{
                sessionStorage.setItem('role', 'default')
              }
            }
          }


          if(!isThereAnRole){
            setLoginError({
              status: true,
              message: 'Usuário ou senha inválidos'
            })
            return false
          }
        sessionStorage.setItem('gate', data.gate)
        sessionStorage.setItem('name', userCredential?.user?.displayName || 'Verne')
        
        sessionStorage.setItem('user-key', JSON.stringify({user_key: getRolesAndToken?.data?.token.apiKey, tenant_id: BASE.company_data.tenant}))
        }
      }

      sessionStorage.setItem('auth', 'true')



      // if (response.role === 'avanti' || response.role === 'avantiadmin') {
      //   return (window.location.href = '/avanti')
      // } else if (response.role === 'bedimin') {
      //   return (window.location.href = '/bepass')
      // }
      // return (window.location.href = '/dashboard')


    return (window.location.href = '/dashboard')
    } catch (err: any) {
      setLoginError({
        status: true,
        message: 'Usuário ou senha inválidos'
      })
    }

    return false


    fetchWithApiKey(`${BASE.api.base_url}${BASE.api.access_login}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: CONFIG.API_KEY
      },
      body: JSON.stringify({
        login: data.user,
        password: data.password
      })
    })
      .then((resg: { json: () => any }) => resg.json())
      .then((response: any) => {
        if (response.token) {
          console.log(response)

          if(response.role === 'museum'){
            return setLoginError({
              status: true,
              message: 'Usuário ou senha inválidos'
            })
          }
          sessionStorage.setItem('auth', 'true')
          sessionStorage.setItem('gate', data.gate)
          sessionStorage.setItem('token', response.id)
          sessionStorage.setItem('name', response.name)
          sessionStorage.setItem('role', response.role)
          if (response.role === 'avanti' || response.role === 'avantiadmin') {
            return (window.location.href = '/avanti')
          } else if (response.role === 'bedimin') {
            return (window.location.href = '/bepass')
          }
          return (window.location.href = '/dashboard')
        }

        setLoginError({
          status: true,
          message: 'Usuário ou senha inválidos'
        })
      })
      .catch((error: any) => {
        setLoginError({
          status: true,
          message: 'Usuário ou senha inválidos'
        })
      })
  }

  return (
    <React.Fragment>
      <div className="auth-wrapper">
        <div className="auth-content">
          <Card className="borderless text-center">
            <Card.Body>
              <div className="mb-4">
                <img src={logoBePass} className='login-logo' />
              </div>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <FloatingLabel
                  className="mb-3"
                  controlId="floatingPassword"
                  label="Usuário"
                >
                  <Form.Control
                    type="text"
                    placeholder="Usuário"
                    {...register('user', { required: true })}
                  />
                  {errors.user && (
                    <Alert variant="danger">Por favor, informe um e-mail</Alert>
                  )}
                </FloatingLabel>

                <FloatingLabel
                  className="mb-3"
                  controlId="floatingPassword"
                  label="Senha"
                >
                  <Form.Control
                    type="password"
                    placeholder="Senha"
                    {...register('password', { required: true })}
                  />
                  {errors.password && (
                    <Alert variant="danger">Por favor, informe uma senha</Alert>
                  )}
                </FloatingLabel>

                {/* <Form.Group
                  controlId="exampleForm.ControlSelect1"
                  className="mb-2"
                >
                  <select
                    className="form-control"
                    {...register('gate', { required: true })}
                  >
                    <option value="Matarazzo">Matarazzo</option>
                    <option value="Palestra">Palestra</option>
                    <option value="Conveniencia">Conveniência</option>
                    <option value="Estacionamento">Estacionamento</option>
                    <option value="Operacional">Operacional</option>
                  </select>
                </Form.Group> */}

                {loginError.status && (
                  <Alert variant="danger">{loginError.message}</Alert>
                )}

                <Form.Group>
                  <Button
                    type="submit"
                    className="mb-0 mt-4 w-100 btn-success"
                  // onClick={() => clearErrors()}
                  >
                    Entrar
                  </Button>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className='copyright-botton'>Desenvolvido por <a href="#" className="href">Bepass</a> | Todos os direitos reservados</div>
    </React.Fragment>
  )
}

export default Login
