import React, { useCallback, useEffect, useState } from 'react'

import {useLocation} from "react-router-dom";
import './App.sass'

import './index.scss'


import DashDefault from './views/Dashboard/Dashboard'
import Profile from './views/Profile/Profile'
import { SideBar } from './shared/sidebar'
import Monitoring from './views/Monitoring/Monitoring'

import { BrowserRouter, Route, Link, Routes, useParams } from 'react-router-dom'
import Login from './views/Auth/Login'
import VisitorsList from './views/VisitorList/Visitors'
import CreateUser from './views/Auth/CreateUser'
import EditUser from './views/Auth/EditUser'
import Approvers from './Approvers/Approvers'
import GameReports from './views/Reports/GameReports'
import Reports from './views/Reports/Reports'
import DashAvanti from './views/Dashboard/DashboardAvanti'
import Companies from './Companies/Companies'
import VisitorTypes from './VisitorTypes/VisitorTypes'
import GameDistribution from './views/Game/GameDistribution'
import GameTickets from './views/Game/GameTickets'
import Multiclubes from './views/Multiclubes/Multiclubes'
import DashBipBop from './views/Dashboard/DashboardBipBop'
import TerminalsPage from './views/Terminals/TerminalsPage'
import PassportPage from './views/Passports/PassportPage';

function App() {


  const queryParameters = new URLSearchParams(window.location.search)
  const token = queryParameters.get("token")
  const gate = '?portaria=' + queryParameters.get('portaria')

const auth = sessionStorage.getItem('auth')
  if(window.location.pathname   == '/multiclubes' && token === '1e0a0cabe632d8074361cb59d634405') {
    window.location.href = '/multiclubes' + gate
    sessionStorage.setItem('auth', 'true')
  } else if (auth !== 'true' && window.location.pathname !== '/') {
    window.location.href = '/'
  }

  return (
    <>
      {/* <DashDefault /> */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/multiclubes" element={<Multiclubes /> } />
          {/* <Route
            path="/dashboard"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <DashDefault />
                  </div>
                </div>
              </>
            }
          /> */}
          <Route
            path="/dashboard"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <DashAvanti />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/bepass"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <DashBipBop/>
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/monitoring"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <Monitoring />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/reporting"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <Reports />
                    {/* <GameReports /> */}
                  </div>
                </div>
              </>
            }
          />

          <Route
            path="/game-report"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <GameReports />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/visitors"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <VisitorsList />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/user/create"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <CreateUser />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/profile"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <Profile />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/game/distribution"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <GameDistribution />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/game/tickets"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <GameTickets />
                  </div>
                </div>
              </>
            }
          /><Route
            path="/terminals"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <TerminalsPage />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/passports"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <PassportPage />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/approvers"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <Approvers />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/companies"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <Companies />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/visitor-types"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <VisitorTypes />
                  </div>
                </div>
              </>
            }
          />
          <Route
            path="/user/edit/:id"
            element={
              <>
                <SideBar />
                <div className="pcoded-main-container">
                  <div className="pcoded-content">
                    <EditUser />
                  </div>
                </div>
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
