import { BASE } from "./constants";

export const fetchWithApiKey = async (url: string, options: RequestInit = {}) => {

  const sessionItem:any = window.sessionStorage.getItem('user-key')
  const {user_key, tenant_id} = sessionItem ? JSON.parse(sessionItem): {user_key:null, tenant_id: null}

  
  const headers = {
    ...options.headers,
    'x-api-key': user_key,
    'x-tenant-id': BASE.company_data.tenant
  };

  const newOptions = {
    ...options,
    headers,
  };

  return fetch(url, newOptions);
};